import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/_App/Navbar";
import Footer from "../components/_App/Footer";
import PageBanner from '../components/Common/PageBanner';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemPanel,
    AccordionItemButton
} from 'react-accessible-accordion';

import Manufacturing1 from "../assets/images/manufacturing1.png";
import Manufacturing2 from "../assets/images/manufacturing2.png";

const Manufacturing = () => (
    <Layout>
        <SEO title="Manufacturing Services" /> 

        <Navbar />


        <div className="services-details-area ptb-80">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 services-details">
                        <div className="services-details-desc">
                            <h3>Manufacturing Services</h3>
                            <p>Our team has over 40 years of experience in cost reduction and establishing manufacturing across the globe. Faraya's global partners in manufacturing are able to scale your product at a price that is hard to beat.
                            </p>

                        </div>
                    </div>

                    <div className="col-lg-6 services-details-image">
                        <img 
                            src={Manufacturing1} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>
                </div>

                <div className="separate"></div>

                <div className="row align-items-center">
                    <div className="col-lg-6 services-details-image">
                        <img 
                            src={Manufacturing2} 
                            className="animate__animated animate__fadeInUp" 
                            alt="image" 
                        />
                    </div>

                    <div className="col-lg-6 services-details">
                        <div className="services-details-desc">
                            <p>
                            Whether you are taking a new product to market, or seeking to reduce costs on an existing product, our team can ensure that you are getting the best quality product manufactured at a great value.
                            </p>

                            <div className="services-details-accordion">
                                <Accordion allowZeroExpanded preExpanded={['a']}>
                                    <AccordionItem uuid="a">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                   Are you Able to Manufacture My Product in the U.S?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Yes, we have manufacturing partners throughout the United States, for clients who prefer to keep their manufacturing efforts within the states.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="b">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                    How Many Units Do I Need To Manufacture?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            Yes, our team excels at analyzing your current supply chain and finding opportunities for efficiencies, new partnerships, and cost savings.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>

                                    <AccordionItem uuid="c">
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <span>
                                                  Can You Optimize My Current Supply Chain?
                                                </span>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            <p>
                                            We are able to get your product manufactured regardless of batch size. However, the cost per unit will depend on the scale of the manufacturing effort.                                            </p>
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default Manufacturing;